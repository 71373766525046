export default [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/auth/login.vue"),
        meta: {
            title: "Login",
            showInMenu: false,
            authRequired: false,
        },
    },
    {
        path: "/verify/",
        name: "verify",
        nameInMenu: 'Verify',
        component: () => import("../layouts/verify/Layout.vue"),
        meta: {
            title: "Verify",
            showInMenu: false,
            authRequired: true,
            sectionTitle: 'Verify'
        },
        redirect: {
            name: "verify_telegram"
        },
        children: [
            {
                path: 'telegram/',
                name: 'verify_telegram',
                nameInMenu: 'Telegram',
                icon: 'ri-map-pin-user-line',
                inDropdown: true,
                component: () => import("@/views/auth/verify.vue")
            },
        ]
    },
    {
        path: "/",
        name: "entry",
        meta: {
            authRequired: true,
            title: "Dashboard",
            sectionTitle: 'Dashboard'
        },
        component: () => import("../layouts/layout.vue"),
        children: [
            {
                path: '',
                name: 'HomePage',
                nameInMenu: 'Dashboard',
                icon: "ri-vip-diamond-fill",
                component: () => import("../views/pages/dashboard/Index.vue")
            },
            {
                path: 'inquires/',
                name: 'Inquires',
                nameInMenu: 'Inquires',
                icon: "mdi mdi-message-bulleted",
                component: () => import("../views/pages/inquires/Index.vue")
            },
            {
                path: 'quotes/',
                name: 'Quotes',
                nameInMenu: 'Quotes',
                icon: "mdi mdi-comment-question-outline",
                component: () => import("../views/pages/quotes/Index.vue")
            },
            {
                path: 'kpi/',
                name: 'statistics',
                meta: {
                    title: "KPI Details",
                },
                nameInMenu: 'KPI',
                icon: "ri-bar-chart-fill",
                component: () => import("../views/pages/statistics/Index.vue")
            },
            {
                path: 'sales-report/',
                name: 'sales-report',
                meta: {
                    title: "Sales Report",
                },
                nameInMenu: 'Sales Report',
                icon: "bx bx-bar-chart-square",
                component: () => import("../views/pages/sales-reports/Index.vue")
            },
        ],
    },
    {
        path: "/devs/",
        name: "devs",
        meta: {
            title: "Developers Page",
            showInMenu: false,
            authRequired: true,
        },
        redirect: {
            name: 'devs_index'
        },
        component: () => import("../layouts/layout.vue"),
        children: [
            {
                path: '',
                name: 'devs_index',
                nameInMenu: 'Developers Page',
                component: () => import("../views/devs/Index.vue")
            },
        ],
    },

    {
        path: "/customer_profile/:customer_slug/",
        name: "customer_profile_pre_view",
        meta: {
            title: "Customer Profile Pre View Page",
            authRequired: true,
        },
        redirect: {
            name: 'customer_profile_pre_view_index'
        },
        component: () => import("@/layouts/layout.vue"),
        children: [
            {
                path: '',
                name: 'customer_profile_pre_view_index',
                nameInMenu: 'my.interrail.uz customer page',
                component: () => import("@/views/pages/accounts/customers/ViewProfileIframe.vue")
            },
        ],
    },

    {
        path: "/accounts/",
        name: "accounts_list",
        meta: {
            title: "Accounts",
            sectionTitle: 'Accounts',
            authRequired: true,
            icon: 'ri-map-pin-user-line'
        },
        redirect: {
            name: "users_list"
        },
        component: () => import("../layouts/layout.vue"),
        children: [
            {
                path: 'user/',
                name: 'accounts_users_list',
                nameInMenu: 'Users',
                icon: 'ri-map-pin-user-line',
                inDropdown: true,
                component: () => import("@/views/pages/accounts/users/Index.vue")
            },
            {
                path: 'customer/',
                name: 'accounts_customers_list',
                nameInMenu: 'Customers',
                icon: 'ri-group-line',
                inDropdown: true,
                component: () => import("@/views/pages/accounts/customers/List.vue"),
            },
            {
                path: 'counterparty/',
                name: 'accounts_counterparties_list',
                nameInMenu: 'Counterparties',
                icon: 'ri-group-line',
                inDropdown: true,
                component: () => import("@/views/pages/accounts/counterparties/Index.vue"),
            },
            {
                path: 'logs/',
                name: 'accounts_action_logs',
                nameInMenu: 'Logs',
                icon: 'bx bx-time-five',
                inDropdown: true,
                component: () => import("@/views/pages/accounts/logs/Index.vue"),
            },
        ],
    },

    {
        path: "/accounts/user/:slug/",
        name: "user_profile",
        meta: {
            title: "User Profile",
            showInMenu: false,
            authRequired: true,
        },
        redirect: {
            name: 'user_profile_main'
        },
        component: () => import("../layouts/accounts/User.vue"),
        children: [
            {
                path: '',
                name: 'user_profile_main',
                nameInMenu: 'Profile',
                component: () => import("../views/pages/accounts/users/tabs/Settings.vue"),
            },
            {
                path: 'settings/',
                name: 'user_profile_settings',
                nameInMenu: 'Settings',
                component: () => import("../views/pages/accounts/users/tabs/Settings.vue"),
            },
        ],
    },

    {
        path: "/accounts/customer/:slug/",
        name: "customer_profile",
        meta: {
            title: "Customer Profile",
            showInMenu: false,
            authRequired: true,
        },
        redirect: {
            name: 'customer_profile_main'
        },
        component: () => import("../layouts/accounts/Customer.vue"),
        children: [
            {
                path: '',
                name: 'customer_profile_main',
                nameInMenu: 'Profile',
                component: () => import("@/views/pages/accounts/customers/tabs/Overview.vue"),
            },
            {
                path: 'accounts/',
                name: 'customer_profile_accounts',
                nameInMenu: 'Accounts',
                component: () => import("@/views/pages/accounts/customers/tabs/Accounts.vue"),
            },
            {
                path: 'contracts/',
                name: 'customer_profile_contracts',
                nameInMenu: 'Contracts',
                component: () => import("@/views/pages/accounts/customers/tabs/Contracts.vue"),
            },
            {
                path: 'contracts/:contract_slug/docs/',
                name: 'customer_profile_contracts_docs',
                nameInMenu: 'Contract Documents',
                component: () => import("@/views/pages/accounts/counterparties/components/DocsByContract.vue"),
            },
            {
                path: 'contracts/:contract_slug/orders/',
                name: 'customer_profile_contracts_orders',
                nameInMenu: 'Contract Orders',
                component: () => import("@/views/pages/accounts/customers/components/ContractOrders.vue"),
            },
        ],
    },

    {
        path: "/accounts/counterparty/:slug/",
        name: "counterparty_profile",
        meta: {
            title: "Counterparty Profile",
            showInMenu: false,
            authRequired: true,
        },
        redirect: {
            name: 'counterparty_profile_main'
        },
        component: () => import("../layouts/accounts/Counterparty.vue"),
        children: [
            {
                path: '',
                name: 'counterparty_profile_main',
                nameInMenu: 'Profile',
                component: () => import("@/views/pages/accounts/counterparties/tabs/Overview.vue"),
            },
            {
                path: 'orders/',
                name: 'counterparty_profile_orders',
                nameInMenu: 'Orders',
                component: () => import("@/views/pages/accounts/counterparties/tabs/Orders.vue"),
            },
            {
                path: 'dashboard/',
                name: 'counterparty_profile_dashboard',
                nameInMenu: 'Dashboard',
                component: () => import("@/views/pages/accounts/counterparties/tabs/Dashboard.vue"),
            },
            {
                path: 'contracts/',
                name: 'counterparty_profile_contracts',
                nameInMenu: 'Contracts',
                component: () => import("@/views/pages/accounts/counterparties/tabs/Contracts.vue"),
            },
            {
                path: 'contracts/:contract_slug/acts/',
                name: 'counterparty_profile_contracts_acts',
                nameInMenu: 'Contract Acts',
                component: () => import("@/views/pages/accounts/counterparties/components/ActsByContract.vue"),
            },
            {
                path: 'contracts/:contract_slug/docs/',
                name: 'counterparty_profile_contracts_docs',
                nameInMenu: 'Contract Documents',
                component: () => import("@/views/pages/accounts/counterparties/components/DocsByContract.vue"),
            },
            {
                path: 'accounts/',
                name: 'counterparty_profile_accounts',
                nameInMenu: 'Accounts',
                component: () => import("@/views/pages/accounts/counterparties/tabs/Accounts.vue"),
            },
        ],
    },

    {
        path: "/accounts/terminal/:slug/",
        name: "terminal_profile",
        meta: {
            title: "Terminal Profile",
            authRequired: true,
        },
        redirect: {
            name: 'terminal_profile_main'
        },
        component: () => import("../layouts/accounts/Terminal.vue"),
        children: [
            {
                path: '',
                name: 'terminal_profile_main',
                nameInMenu: 'Profile',
                component: () => import("@/views/pages/accounts/counterparties/tabs/Overview.vue"),
            },
        ]
    },

    {
        path: "/projects/",
        name: "Tasks",
        meta: {
            title: "Tasks",
            sectionTitle: 'Task Management',
            authRequired: true,
        },
        component: () => import("../layouts/tasks/Index.vue"),
        children: [
            {
                path: '',
                name: 'tasks_list',
                nameInMenu: 'ToDo',
                icon: 'mdi mdi-calendar-check-outline',
                component: () => import("../views/pages/tasks/Index.vue"),
            },
            {
                path: ':project_id/',
                name: 'project_tasks_list',
                icon: 'mdi mdi-calendar-check-outline',
                nameInMenu: 'Project tasks',
                component: () => import("../views/pages/tasks/components/TasksList.vue"),
            },
        ],
    },

    {
        path: "/job-descriptions/",
        name: "Job Descriptions",
        meta: {
            title: "Job Descriptions",
            authRequired: true,
        },
        component: () => import("../layouts/layout.vue"),
        children: [
            {
                path: '',
                name: 'processes_list',
                nameInMenu: 'Job Descriptions',
                icon: 'mdi mdi-clipboard-text-outline',
                component: () => import("../views/pages/system-processes/Index.vue"),
            }
        ],
    },


    {
        path: "/applications/",
        name: "Applications",
        meta: {
            title: "Application",
            authRequired: true,
            icon: 'ri-file-paper-line',
            sectionTitle: 'Applications',
        },
        component: () => import("../layouts/layout.vue"),
        children: [
            {
                path: '',
                name: 'applications_list',
                nameInMenu: 'Applications',
                component: () => import("../views/pages/applications/List.vue"),
            },
            {
                path: 'create/',
                name: 'application_create',
                nameInMenu: 'Create',
                inDropdown: true,
                showInMenu: false,
                component: () => import("../views/pages/applications/Create.vue"),
            },
            {
                path: 'update/:id',
                name: 'application_update',
                inDropdown: true,
                nameInMenu: 'Update',
                component: () => import("../views/pages/applications/Update.vue"),
            },
        ],
    },

    {
        path: "/codes/",
        name: "Codes",
        meta: {
            title: "Codes",
            authRequired: true,
        },
        redirect: {
            name: 'codes_list'
        },
        component: () => import("../layouts/layout.vue"),
        children: [
            {
                path: '',
                name: 'codes_list',
                nameInMenu: 'Codes List',
                icon: 'ri-list-ordered',
                component: () => import("../views/pages/codes/List.vue")
            },
        ],
    },

    {
        path: "/orders/",
        name: "orders",
        meta: {
            title: "Orders",
            authRequired: true,
            icon: 'mdi mdi-order-bool-descending'
        },
        component: () => import("../layouts/layout.vue"),
        redirect: {
            name: "orders_list"
        },
        children: [
            {
                path: 'orders-all/',
                name: 'orders_list',
                nameInMenu: 'Orders List',
                inDropdown: true,
                component: () => import("../views/pages/orders/index.vue"),
            },
            {
                path: 'container-shipments/',
                name: 'order_container_list',
                nameInMenu: 'Container shipments',
                inDropdown: true,
                component: () => import("../views/pages/orders/container/list.vue"),
            },
            {
                path: 'container/new/',
                name: 'create_container_order',
                showInMenu: false,
                nameInMenu: 'Create Container Order',
                component: () => import("../views/pages/orders/container/create/Create.vue"),
            },
            {
                path: 'container/update/:id',
                name: 'orders_container_update',
                nameInMenu: 'Update Container Order',
                component: () => import("../views/pages/orders/container/update.vue"),
            },
            {
                path: 'container/view/:id',
                name: 'orders_container_detail',
                nameInMenu: 'Container Order Detail',
                component: () => import("../views/pages/orders/container/details/SwitchUI.vue"),
            },
            {
                path: 'conventional-wagons/',
                name: 'order_wagon_list',
                nameInMenu: 'Conventional wagons',
                inDropdown: true,
                component: () => import("../views/pages/orders/wagon/list.vue"),
            },
            {
                path: 'create-wagon/',
                name: 'create_wagon',
                showInMenu: false,
                nameInMenu: 'Create Wagon Order',
                component: () => import("../views/pages/orders/wagon/create/Create.vue"),
            },
            {
                path: 'wagon/update/:id',
                name: 'orders_wagon_update',
                nameInMenu: 'Update Wagon Order',
                component: () => import("../views/pages/orders/wagon/update.vue"),
            },
            {
                path: 'wagon/view/:id',
                name: 'orders_wagon_detail',
                nameInMenu: 'Wagon Order Detail',
                component: () => import("../views/pages/orders/wagon/detail.vue"),
            },
            {
                path: 'wagon/:id/create-application',
                name: 'create_wagon_application',
                nameInMenu: 'Create Wagon Order Application',
                component: () => import("../views/pages/orders/wagon/create-application/index.vue"),
            },
            {
                path: 'empty-wagon/',
                name: 'order_empty_wagon_list',
                nameInMenu: 'Empty Wagons List',
                inDropdown: true,
                component: () => import("../views/pages/orders/empty_wagon/list.vue"),
            },
            {
                path: 'create-empty-wagon/',
                name: 'create_empty_wagon',
                showInMenu: false,
                nameInMenu: 'Create Empty Wagon Order',
                component: () => import("../views/pages/orders/empty_wagon/create/Create.vue"),
            },
            {
                path: 'empty-wagon/update/:id',
                name: 'orders_empty_wagon_update',
                nameInMenu: 'Update Empty Wagon Order',
                component: () => import("../views/pages/orders/empty_wagon/menu-update.vue"),
            },
            {
                path: 'empty-wagon/view/:id',
                name: 'orders_empty_wagon_detail',
                nameInMenu: 'Empty Wagon Order Detail',
                component: () => import("../views/pages/orders/empty_wagon/detail.vue"),
            },
        ],
    },

    {
        path: "/pre-orders/",
        name: "pre_orders",
        meta: {
            title: "Pre Orders",
            authRequired: true,
            icon: 'mdi mdi-order-bool-descending'
        },
        component: () => import("../layouts/preorders/Index.vue"),
        redirect: {
            name: "pre_orders_under_review_list"
        },
        children: [
            {
                path: 'under-review/',
                name: 'pre_orders_under_review_list',
                nameInMenu: 'Under-Review List',
                inDropdown: true,
                component: () => import("../views/pages/pre-orders/UnderReviewTab.vue"),
            },
            {
                path: 'approved/',
                name: 'pre_orders_approved_list',
                nameInMenu: 'Approved-Orders List',
                inDropdown: true,
                component: () => import("../views/pages/pre-orders/ApprovedTab.vue"),
            },
            {
                path: 'completed/',
                name: 'pre_orders_completed_list',
                nameInMenu: 'Completed-Orders List',
                inDropdown: true,
                component: () => import("../views/pages/pre-orders/CompletedTab.vue"),
            },
            {
                path: 'cancelled/',
                name: 'pre_orders_cancelled_list',
                nameInMenu: 'Cancelled-Orders List',
                inDropdown: true,
                component: () => import("../views/pages/pre-orders/CancelledTab.vue"),
            },
            // PRE ORDER UPDATE ROUTERS
            {
                path: 'under-review/container/:id/edit/',
                name: 'pre_orders_container_update',
                nameInMenu: 'Container Update',
                component: () => import("../views/pages/pre-orders/edit/ContainerOrder.vue"),
            },
            {
                path: 'under-review/wagon/:id/edit/',
                name: 'pre_orders_wagon_update',
                nameInMenu: 'Wagon Update',
                component: () => import("../views/pages/pre-orders/edit/WagonOrder.vue"),
            },
            {
                path: 'under-review/empty-wagon/:id/edit/',
                name: 'pre_orders_empty_wagon_update',
                nameInMenu: 'Empty Wagon Update',
                component: () => import("../views/pages/pre-orders/edit/EmptyWagonOrder.vue"),
            },
        ],
    },


    {
        path: "/avr/",
        name: "Avr",
        meta: {
            title: "AVR",
            authRequired: true,
            sectionTitle: 'AVR',
        },
        component: () => import("../layouts/layout.vue"),
        children: [
            {
                path: '',
                name: 'avr_list',
                nameInMenu: 'Avr List',
                icon: 'mdi mdi-microsoft-excel',
                component: () => import("../views/pages/avr/Index.vue"),
            },
            {
                path: 'new/container/',
                name: 'avr_create_container',
                nameInMenu: 'Create AVR - Container',
                icon: 'ri-exchange-dollar-fill',
                component: () => import("../views/pages/avr/Create.vue"),
                inDropdown: true,
                showInMenu: false,
            },
            {
                path: 'container/:avr_id/',
                name: 'avr_container_details',
                nameInMenu: 'Container AVR Details',
                icon: 'ri-exchange-dollar-fill',
                component: () => import("../views/pages/avr/Create.vue"),
                inDropdown: true,
            },

            {
                path: 'new/wagon/',
                name: 'avr_create_wagon',
                nameInMenu: 'Create AVR - Wagon',
                icon: 'ri-exchange-dollar-fill',
                component: () => import("../views/pages/avr/CreateWagon.vue"),
                inDropdown: true,
                showInMenu: false,
            },
            {
                path: 'wagon/:avr_id/',
                name: 'avr_wagon_details',
                nameInMenu: 'Wagon AVR Details',
                icon: 'ri-exchange-dollar-fill',
                component: () => import("../views/pages/avr/CreateWagon.vue"),
                inDropdown: true,
            },

            {
                path: 'new/empty-wagon/',
                name: 'avr_create_empty_wagon',
                nameInMenu: 'Create AVR - Empty Wagon',
                icon: 'ri-exchange-dollar-fill',
                component: () => import("../views/pages/avr/CreateEmptyWagon.vue"),
                inDropdown: true,
                showInMenu: false,
            },
            {
                path: 'empty-wagon/:avr_id/',
                name: 'avr_empty_wagon_details',
                nameInMenu: 'Empty Wagon AVR Details',
                icon: 'ri-exchange-dollar-fill',
                component: () => import("../views/pages/avr/CreateEmptyWagon.vue"),
                inDropdown: true,
            },
        ],
    },

    {
        path: "/invoices/",
        name: "Invoices",
        meta: {
            title: "Invoices",
            authRequired: true,
            icon: 'mdi mdi-file-document-outline'
        },
        component: () => import("../layouts/layout.vue"),
        children: [
            {
                path: 'list/',
                name: 'invoices_list',
                meta: {
                    title: "Invoices List",
                },
                nameInMenu: 'Invoices List',
                component: () => import("../views/pages/invoices/List.vue"),
            },
            {
                path: 'create/container/:order_number/',
                name: 'container_invoice_create',
                meta: {
                    title: "Dashboard",
                },
                nameInMenu: 'Create Container Invoice',
                component: () => import("../views/pages/invoices/Container.vue"),
            },
            {
                path: 'create/wagon/:order_number/',
                name: 'wagon_invoice_create',
                meta: {
                    title: "Dashboard",
                },
                nameInMenu: 'Create Wagon Invoice',
                component: () => import("../views/pages/invoices/Wagon.vue"),
            },
            {
                path: 'create/empty-wagon/:order_number/',
                name: 'empty_wagon_invoice_create',
                meta: {
                    title: "Dashboard",
                },
                nameInMenu: 'Create Empty Wagon Invoice',
                component: () => import("../views/pages/invoices/EmptyWagon.vue"),
            }
        ],
    },

    {
        path: "/payments/",
        name: "Payments",
        meta: {
            title: "Payments",
            authRequired: true,
        },
        component: () => import("../layouts/layout.vue"),
        children: [
            {
                path: '',
                name: 'payments_list',
                nameInMenu: 'Payments',
                icon: 'ri-exchange-dollar-fill',
                component: () => import("../views/pages/payments/Index.vue"),
            }
        ]
    },

    {
        path: "/rates/",
        name: "Rates",
        meta: {
            title: "Rates",
            authRequired: true,
        },
        component: () => import("../layouts/layout.vue"),
        children: [
            {
                path: '',
                name: 'rates_list',
                nameInMenu: 'Rates',
                icon: 'ri-exchange-dollar-fill',
                component: () => import("../views/pages/rates/index.vue"),
            }
        ],
    },

    {
        path: "/terminal/",
        name: "terminal",
        meta: {
            title: "Terminal",
            sectionTitle: 'Terminal',
            authRequired: true,
        },
        component: () => import("../layouts/layout.vue"),
        redirect: {
            name: "terminal_index"
        },
        children: [
            {
                path: '',
                name: 'terminal_index',
                nameInMenu: 'Terminals',
                component: () => import("../views/pages/terminal/Index.vue")
            },
        ]
    },

    {
        path: "/trains/",
        name: "trains",
        meta: {
            title: "Trains",
            authRequired: true,
            icon: 'bx bx-train',
        },
        component: () => import("../layouts/layout.vue"),
        redirect: {
            name: "trains_list"
        },
        children: [
            {
                path: '',
                name: 'trains_list',
                nameInMenu: 'Trains',
                inDropdown: true,
                component: () => import("../views/pages/trains/List.vue")
            },
            {
                path: 'schedule/',
                name: 'train_schedule',
                nameInMenu: 'Schedule',
                inDropdown: true,
                component: () => import("../views/pages/trains/schedule/Index.vue")
            },
        ]
    },

    {
        path: "/trains/:train_slug/",
        name: "trains_profiles",
        meta: {
            title: "Trains",
            authRequired: true,
        },
        component: () => import("../layouts/trains/Details.vue"),
        redirect: {
            name: "train_details_main"
        },
        children: [
            {
                path: '',
                name: 'train_details_main',
                nameInMenu: 'Main',
                component: () => import("../views/pages/trains/details/tabs/MainTab.vue")
            },
            {
                path: 'companies/',
                name: 'train_details_smgs',
                nameInMenu: 'SMGS',
                component: () => import("../views/pages/trains/details/tabs/SMGS.vue")
            },
            {
                path: 'companies/',
                name: 'train_details_companies',
                nameInMenu: 'Companies',
                component: () => import("../views/pages/trains/details/tabs/CompaniesTab.vue")
            },
            {
                path: 'wagon-upload/',
                name: 'train_details_wagon_upload',
                nameInMenu: 'Wagon Upload',
                component: () => import("../views/pages/trains/details/tabs/ExcelTab.vue")
            },
            {
                path: 'dislocation/',
                name: 'train_details_dislocation',
                nameInMenu: 'Dislocation',
                component: () => import("../views/pages/trains/details/tabs/DislocationTab.vue")
            },
            {
                path: 'settings/',
                name: 'train_details_settings',
                nameInMenu: 'Settings',
                component: () => import("../views/pages/trains/details/tabs/SettingsTab.vue")
            },
        ]
    },

    {
        path: "/smgs/",
        name: "Smgs",
        meta: {
            title: "SMGS",
            authRequired: true,
            icon: 'bx bxs-file',
        },
        component: () => import("../layouts/layout.vue"),
        redirect: {
            name: "smgs_printing_list"
        },
        children: [
            {
                path: 'printing/',
                name: 'smgs_printing_list',
                meta: {
                    title: "SMGS Printing",
                    showInMenu: false,
                },
                nameInMenu: 'SMGS Printing',
                inDropdown: true,
                component: () => import("../views/pages/smgs/PrintingList.vue"),
            },
            {
                path: 'documents/',
                name: 'smgs_documents',
                meta: {
                    title: "Documents List",
                },
                nameInMenu: 'Documents List',
                inDropdown: true,
                component: () => import("../views/pages/smgs/Documents.vue"),
            },
            {
                path: 'single/',
                name: 'smgs_single',
                meta: {
                    title: "Single SMGS",
                },
                nameInMenu: 'Single SMGS',
                inDropdown: true,
                component: () => import("../views/pages/smgs/Single.vue"),
            },
            {
                path: 'update/:id',
                name: 'smgs_update',
                meta: {
                    title: "Dashboard",
                },
                nameInMenu: 'SMGS Update',
                component: () => import("../views/pages/smgs/Update.vue"),
            },
            {
                path: 'documents/:id',
                name: 'smgs_documents_update',
                meta: {
                    title: "Extractor",
                },
                nameInMenu: 'SMGS Documents Update',
                component: () => import("../views/pages/smgs/DocumentsUpdateNew.vue"),
            },
        ],
    },

    {
        path: "/inter_rail_uz/",
        name: "inter_rail_uz",
        meta: {
            title: "Inter Rail (interrail.uz)",
            sectionTitle: 'Inter Rail (interrail.uz)',
            authRequired: true,
            icon: 'ri-settings-2-fill'
        },
        component: () => import("../layouts/layout.vue"),
        redirect: {
            name: "inter_rail_uz_news"
        },
        children: [
            {
                path: 'news/',
                name: 'inter_rail_uz_news',
                nameInMenu: 'News',
                inDropdown: true,
                component: () => import("../views/pages/inter_rail_uz/news/Index.vue"),
            },
        ]
    },

    {
        path: "/general/",
        name: "General",
        meta: {
            title: "General",
            sectionTitle: 'General',
            authRequired: true,
            icon: 'ri-settings-2-fill'
        },
        component: () => import("../layouts/layout.vue"),
        redirect: {
            name: "general_products_list"
        },
        children: [
            {
                path: 'offers/',
                name: 'general_offers_list',
                nameInMenu: 'Offers',
                inDropdown: true,
                component: () => import("../views/pages/general/offers/List.vue"),
            },
            {
                path: 'sales-plan-targets/',
                name: 'general_sales_plan_targets',
                nameInMenu: "Sales Plans` Targets",
                inDropdown: true,
                component: () => import("../views/pages/general/sales-plans/index.vue"),
            },
            {
                path: 'products/',
                name: 'general_products_list',
                nameInMenu: 'Products',
                inDropdown: true,
                component: () => import("../views/pages/general/products/List.vue"),
            },
            {
                path: 'stations/',
                name: 'general_stations',
                nameInMenu: 'Stations',
                inDropdown: true,
                component: () => import("../views/pages/general/stations/List.vue"),
            },
            {
                path: 'categories/',
                name: 'general_categories',
                nameInMenu: 'Categories',
                inDropdown: true,
                component: () => import("../views/pages/general/categories/List.vue"),
            },
            {
                path: 'counterparties/',
                name: 'general_counterparties',
                nameInMenu: 'Counterparties',
                inDropdown: true,
                component: () => import("../views/pages/general/counterparties/List.vue"),
            },
            {
                path: 'terminals/',
                name: 'general_terminals',
                nameInMenu: 'Terminals',
                inDropdown: true,
                component: () => import("../views/pages/general/terminal/List.vue"),
            },
            {
                path: 'shipment-products/',
                name: 'general_shipment_products',
                nameInMenu: 'Shipment Products',
                inDropdown: true,
                component: () => import("../views/pages/general/shipment-products/List.vue"),
            },
            {
                path: 'roles/',
                name: 'general_roles',
                nameInMenu: 'Roles',
                inDropdown: true,
                component: () => import("../views/pages/general/role/List.vue"),
            },
            {
                path: 'companies/',
                name: 'general_companies',
                nameInMenu: 'Companies',
                inDropdown: true,
                component: () => import("@/views/pages/general/companies/List.vue"),
            },
        ],
    },

    // ERRORS ERRORS ERRORS

    {
        path: "/errors/403",
        name: "forbidden",
        meta: {
            title: "Access denied",
            showInMenu: false,
            authRequired: false,
        },
        component: () => import("../views/auth/errors/offline.vue"),
    },

    {
        path: "/errors/ofline",
        name: "offline",
        meta: {
            title: "Access denied",
            showInMenu: false,
            authRequired: false,
        },
        component: () => import("../views/auth/errors/500.vue"),
    },

    {
        path: '/:pathMatch(.*)*',
        meta: {
            title: "Page not found",
            showInMenu: false,
            authRequired: false,
        },
        component: () => import("../views/auth/errors/404-cover.vue"),
    }
];